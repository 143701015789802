/**
 * @module copyToClipboard
 * @description Simple method for copy text to clipboard. Useful to use in event listeners.
 * @example // Enable coping text from data-text-to-copy attribute on click by element with class-name js-copyText:
 * copyToClipboard.init();
 * // Element example: <button class="js-copyText" data-text-to-copy="Any text to copy">Copy text</button>
 *
 * @example // Copy arbitrary text by passing it explicitly:
 * copyToClipboard.copy('Any arbitrary text to copy');
 */

/**
 * Change copy button state
 * @param {HTMLButtonElement} copyButton
 * @param {HTMLButtonElement} previousCopyButton
 */
function updateCopyButtonState(copyButton, previousCopyButton) {
    if (previousCopyButton) {
        const label = previousCopyButton.getAttribute('data-original-text');
        previousCopyButton.innerHTML = label || 'Copy';
        previousCopyButton.classList.remove('is-copied');
    }

    copyButton.setAttribute('data-original-text', copyButton.innerHTML);
    copyButton.innerHTML = 'Copied';
    copyButton.classList.add('is-copied');
}

/**
 * @param {HTMLButtonElement} button
 * @returns {string}
 */
function copyTextFromAttributes(button) {
    return button.dataset.textToCopy;
}

/**
 * @param {HTMLButtonElement} button
 * @returns {string}
 */
function copyHTMLFromNode(button) {
    const nodeId = button.dataset.nodeToCopy;
    const targetElement = document.getElementById(nodeId);
    if (!targetElement) return;

    // `<pre>` element has escaped symbol using HTML entities.
    if (targetElement.constructor === HTMLPreElement) return targetElement.innerText;
    return targetElement.innerHTML;
}

/**
 * Copy given text to the clipboard
 *
 * @example copyToClipboard.copy('Any arbitrary text to copy');
 *
 * @param {string} stringToCopy Text to copy
 * @returns {Promise} Whether successfully copied or not
 */
function copyToClipboard(stringToCopy) {

    return new Promise((resolve, reject) => {
        navigator.clipboard.writeText(stringToCopy).then(() => {
            resolve();
        }).catch(() => {
            reject(new Error('Clipboard API doesn’t supported by your browser.'));
        });
    });
}

/**
 * Make all HTMLElements (usually 'button') with 'js-copyText' class-name
 * able to copy text from its data-text-to-copy attribute to clipboard.
 * @example <button class="js-copyText" data-text-to-copy="Any text to copy">Copy text</button>
 */
function initComponent() {
    document.body.addEventListener('click', (event) => {
        const copyButton = event.target.closest('.js-copyText');

        if (copyButton) {
            const textToCopy = copyTextFromAttributes(copyButton) || copyHTMLFromNode(copyButton);
            if (textToCopy) {
                event.preventDefault();
                copyToClipboard(textToCopy)
                    .then(() => {
                        const previousCopyButton = document.querySelector('.is-copied');
                        updateCopyButtonState(copyButton, previousCopyButton);
                    })
                    .catch(() => {
                        window.prompt('Copy to clipboard: Ctrl+C (Win) or Cmd+C (Mac), Enter', textToCopy);
                    });
            }
        }
    });
}

document.readyState === 'loading' ? document.addEventListener('DOMContentLoaded', initComponent) : initComponent();
